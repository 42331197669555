import React from 'react'
import styled from 'styled-components'

import Newsletter from '../layout/Newsletter'

import P from '../styled/Paragraph'
import W from '../styled/Wrapper'

const Wrapper = styled(W)`
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

const Image = styled.img`
  position: absolute;
  top: -20px;
  left: -20px;
  display: block;
  max-width: none;
  width: calc(100% + 40px);
  height: calc(100% + 40px);
  object-fit: cover;
  z-index: 1;
`

const VerticalWrap = styled(W)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: ${({theme}) => theme.white};;
  position: relative;
  z-index: 2;
  margin: 140px 0 110px;
  width: 100%;
  padding: 0;

  @media only screen and (min-width: 768px) {
      align-items: flex-start;
  }

  @media only screen and (min-width: 1024px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1125px;
    width: 100%;
    margin: 0;
  }
`

const Content = styled.span`
  width: 100%;
  margin: 0 0 114px;

  @media only screen and (min-width: 1024px) {
    margin: -10px 0 0;
  }
`

const Heading = styled(P)`
  margin: 0 0 30px;
`

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const Text = styled(P)`
  font-family: ${({ theme }) => theme.fontSansMedium};
  line-height: 18px;
`

const Soon = ({ imageUrl, headline, text }) => {
  return (
    <Wrapper>
      <Image src={imageUrl} />

      <VerticalWrap>
        <Content>
          <Heading as="h2" size="l">
            {headline}
          </Heading>

          <List>
            {text &&
              text.length > 0 &&
              text.map((el, i) => {
                return (
                  <Text uppercase size="xs" as="li" key={i}>
                    {el}
                  </Text>
                )
              })}
          </List>
        </Content>

        <Newsletter />
      </VerticalWrap>
    </Wrapper>
  )
}

export default Soon
