import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'

import P from '../styled/Paragraph'
import W from '../styled/Wrapper'

const Wrapper = styled.section`
  background-color: ${({ theme }) => theme.white};
  border-bottom: 1px solid ${({ theme }) => theme.black};
`

const Header = styled.header`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  max-width: none;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Content = styled(W)`
  margin: 115px auto;
  width: 100%;
  max-width: 1124px;

  @media only screen and (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 0 auto;
    padding: 150px 0;
  }
`

const About = styled.div`
  align-self: flex-start;
  max-width: 940px;
`

const AboutParagraph = styled(P)`
  margin-bottom: 40px;

  @media only screen and (min-width: 1024px) {
    margin-bottom: 60px;
  }
`

const Details = styled.ul`
  margin: 0 0 28px;
  padding: 0;
  list-style: none;
  width: 100%;
  max-width: 500px;
`

const Address = styled(P)`
  width: 100%;
  max-width: 500px;
`

const AddressItem = styled(P)`
  font-family: ${({ theme }) => theme.fontSansMedium};
  line-height: 18px;
`

const StyledIframe = styled.iframe`
  min-height: 240px;
  position: absolute;
  top: 0;
  /* margin: 16px 0; */
`

const Open = ({ imageUrl, exhibitionTitle, about, details, address }) => {
  const iframeEl = useRef(null)

  useEffect(() => {
    const handleResize = () => {
      if (iframeEl.current) {
        iframeEl.current.width =
          typeof window !== 'undefined' ? window.innerWidth : 853
        iframeEl.current.height =
          typeof window !== 'undefined' ? window.innerHeight : 480
      }
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [iframeEl])

  return (
    <Wrapper>
      <Header>
        <StyledIframe
          src="https://virtualexhibition.v21artspace.com/discovered-designers-for-tomorrow"
          frameBorder="0"
          allowFullScreen
          width={typeof window !== 'undefined' ? window.innerWidth : 853}
          height={typeof window !== 'undefined' ? window.innerHeight : 480}
          ref={iframeEl}
        ></StyledIframe>
      </Header>

      <Content>
        <About>
          {about &&
            about.length > 0 &&
            about.map((paragraph, i) => {
              return (
                <AboutParagraph size="l" as="p" key={i}>
                  {paragraph}
                </AboutParagraph>
              )
            })}
        </About>

        <Details>
          {details &&
            details.length > 0 &&
            details.map((item, i) => {
              return (
                <P size="m" as="li" key={i}>
                  {item}
                </P>
              )
            })}
        </Details>

        <Address as="div">
          {address &&
            address.length > 0 &&
            address.map((item, i) => {
              return (
                <AddressItem uppercase size="xs" as="div" key={i}>
                  {item}
                </AddressItem>
              )
            })}
        </Address>
      </Content>
    </Wrapper>
  )
}

export default Open
