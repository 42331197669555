import React from 'react'

import SEO from '../components/seo'

import Soon from '../components/exhibition/Soon'
import Open from '../components/exhibition/Open'


const Exhibition = props => {

  const data = props.data.dis.pageExhibition

  // const imageUrl = `https://media.graphcms.com/resize=w:800,h:500,fit:crop/${data.backgroundImage.handle}`

  return (
    <div>
      <SEO
        title={data.seoTitle}
        description={data.seoDescription}
        image={data.seoImage}
      />
      <div id="d-01"></div>
      {
        // <Soon imageUrl={imageUrl} headline={data.headline} text={data.content} />
      }
      <Open
        exhibitionTitle={data.exhibitionTitle}
        about={data.about}
        details={data.details}
        address={data.address}
      />
      <div id="l-01"></div>
      <div id="d-02"></div>
      <div id="l-02"></div>
      <div id="d-03"></div>
      <div id="l-03"></div>
      <div id="d-04"></div>
      <div id="l-04"></div>
    </div>
  )
}


export default Exhibition

export const query = graphql`
  query PageExhibitionQuery {
    dis {
      pageExhibition(where: { id: "cki8xaohk3fjn0a12md21lmi6" }) {
        seoTitle
        seoDescription
        seoImage {
          handle
          width
          height
          url
        }
        headline
        content
        backgroundImage {
          handle
        }
        exhibitionTitle
        about
        details
        address
      }
    }
  }
`